<template>
  <div class="main-wrapper">
    <title-bar
      title="Report Abuse"
      subtitle=""
    />
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 ">
                <div class="about-info">
                  <div class="about-desc">
                    <p
                      class="mb-0 font-weight-normal text-justify"
                      style="font-size:20px;"
                    >
                      Thriggle users self-publish images, videos, posts, and comments subject to the Thriggle Terms of Use. 
                      If you believe that someone is violating these policies please use our maintenance form added to all posts and check the appropriate box before sending.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		TitleBar: TitleBar
	}
};
</script>
